/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/scss/theme.scss'

import React from "react"
import PropTypes from 'prop-types'

export const wrapRootElement = ({ element }) => <>
  {element}
</>

wrapRootElement.propTypes = {
  element: PropTypes.any
}
